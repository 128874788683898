import React from "react"
import Layout from "../components/layout"
import {graphql, Link} from "gatsby"
import Carousel from "react-bootstrap/Carousel"
import Img from "gatsby-image"
import { HelmetDatoCms } from 'gatsby-source-datocms'
import banner1 from "../../static/banner_1.png"
import banner2 from "../../static/banner_2.png"

const Index = ({ data: { home } }) => (
    <Layout className="home">
        <HelmetDatoCms seo={home.seoMetaTags} />
        <div className="banners">
            <div className="banner">
                <h1 dangerouslySetInnerHTML={{
                    __html: home.banner,
                }}
                />
                <a href="#" className="btn btn--blue">{home.bannerButtonLabel}</a>
                <Carousel fade={true} controls={false} indicators={false} slide={false}>
                    <Carousel.Item>
                        <img src={banner1} alt=""/>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src={banner2} alt=""/>
                    </Carousel.Item>
                </Carousel>
            </div>
        </div>
        <div className="box-wrapper">
            <div className="container">
                <div className="box box--lg">
                    <div className="box__content">
                        <p className="title">{home.fullBoxTitle}</p>
                        <p>{home.fullBoxContent}</p>
                    </div>
                    <div className="box__footer">
                        <Link to={home.fullBoxButtonLink}>{home.fullBoxButtonLabel}</Link>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="box">
                    <Img fluid={home.leftBoxImage.fluid} />
                    <div className="box__content">
                        <p className="title">{home.leftBoxTitle}</p>
                        <p>{home.leftBoxContent}</p>
                    </div>
                </div>
                <div className="box">
                    <Img fluid={home.rightBoxImage.fluid} />
                    <div className="box__content">
                        <p className="title">{home.rightBoxTitle}</p>
                        <p>{home.rightBoxContent}</p>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)

export default Index

export const query = graphql`
  query HomeQuery {
    home: datoCmsHomePage {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      banner
      bannerButtonLabel
      bannerButtonLink
      fullBoxTitle
      fullBoxContent
      fullBoxButtonLabel
      fullBoxButtonLink
      leftBoxImage {
        fluid(imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsSizes
        }
      }
      leftBoxTitle
      leftBoxContent
      rightBoxImage {
        fluid(imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsSizes
        }
      }
      rightBoxTitle
      rightBoxContent
    }
  }
`